@import '~antd/dist/antd.css';

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  font-family: Questrial,Microsoft YaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
  /*background:url(../img/bg.jpg) repeat-x center top;*//*background-repeat: no-repeat;平铺*/
}
body:before {
  content: ' ';
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent url();
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  background-attachment: fixed;
  background-position: 50% 20%;
}
/*.bak*/
/*{*/
/*  background: url(detail-top.png) repeat-x -0px -0px;*/
/*  word-wrap: break-word; word-break: break-all; !*自动换行*!*/
/*  font-family: Microsoft YaHei,Tahoma,Verdana,Helvetica, Arial, sans-serif,SimHei;*/
/*  font-family: Century Gothic,Arial;*/

/*  background:url(images/bg.jpg) #fff;*/
/*  background-repeat: repeat-y;*/
/*  background-position: 50%;!*背景居中*!*/

/*  background:url(images/bg.jpg) #fff;background-attachment:fixed;!*背景不动*!*/

/*  background:url(/images/ico2.gif) repeat-x -0px -0px;*/
/*  filter: chroma(color = #5E5D5A);!*透明指定的色将图片的特殊颜色透明 *!*/
/*  background:url(images/ico.png) no-repeat scroll 3px -51px transparent;*/

/*  background:url(images/ico.png) no-repeat 3px -51px;*/
/*}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-moz-selection { background-color: #131E26; color: #fff; }
::selection { background-color: #131E26; color: #fff; } /* Works in Safari */

a{text-decoration: none;color: #666666;
  transition:     color 300ms ease-in-out, background-color 300ms ease-in-out;
  -moz-transition:  color 300ms ease-in-out, background-color 300ms ease-in-out;
  -webkit-transition: color 300ms ease-in-out, background-color 300ms ease-in-out;
  -o-transition:    color 300ms ease-in-out, background-color 300ms ease-in-out;
}
a:hover {text-decoration: none;color: #fd9c3a;
  transition:     color 300ms ease-in-out, background-color 300ms ease-in-out;
  -moz-transition:  color 300ms ease-in-out, background-color 300ms ease-in-out;
  -webkit-transition: color 300ms ease-in-out, background-color 300ms ease-in-out;
  -o-transition:    color 300ms ease-in-out, background-color 300ms ease-in-out;
}

img {border-style: none;vertical-align: top;height: auto;}
a img {
  FILTER: progid:DXImageTransform.Microsoft.Alpha(opacity=100)
}
a:hover img {
  FILTER: progid:DXImageTransform.Microsoft.Alpha(opacity=90,finishopacity=100,style=0)
}
h1, .h1, h2, .h2, h3, .h3 {margin-top: 10px;margin-bottom: 10px;}
input[type="text"] {
  text-align: left;
  -moz-border-radius: 3px;/* Gecko browsers */
  -webkit-border-radius: 3px;   /* Webkit browsers */
  border-radius:3px;/* W3C syntax */
}
input[type="text"]:focus {text-align: left;}
input[type="submit111"],
input[type="reset"],
input[type="button"],
button {
  -webkit-appearance: none;outline:none;resize:none;vertical-align:middle;outline:medium none;;margin: 0px;padding: 0px;border:0px solid #FFF;cursor: pointer;
}

button,input{
  transition:     color 300ms ease-in-out, background-color 300ms ease-in-out;
  -moz-transition:  color 300ms ease-in-out, background-color 300ms ease-in-out;
  -webkit-transition: color 300ms ease-in-out, background-color 300ms ease-in-out;
  -o-transition:    color 300ms ease-in-out, background-color 300ms ease-in-out;
}
button:hover,button:input {
  transition:     color 300ms ease-in-out, background-color 300ms ease-in-out;
  -moz-transition:  color 300ms ease-in-out, background-color 300ms ease-in-out;
  -webkit-transition: color 300ms ease-in-out, background-color 300ms ease-in-out;
  -o-transition:    color 300ms ease-in-out, background-color 300ms ease-in-out;
}

.space {display: inline;padding:0px 20px;font-family: Microsoft YaHei;}
.hide_clip{border: 0 none !important;clip: rect(1px, 1px, 1px, 1px);height: 1px !important;line-height: 0 !important;overflow: hidden;padding: 0 !important;position: absolute !important;
  width: 1px !important;}

.text a{color: #fff;text-align:justify;text-justify:inter-ideograph;cursor:pointer;}
.text a:hover{color: #ccc;text-align:justify;text-justify:inter-ideograph;cursor:pointer;}

.newsfont{font-size:13px; color:#000;font-weight: bold;}
a.newsfont{font-size:13px; color:#000;font-weight: bold;}
a.newsfont:hover{font-size:13px; color:#000;font-weight: bold;}


a.hui:link     {color: #fff;text-decoration: underline;font-family: Arial, Helvetica, sans-serif;}
a.hui:visited  {color: #ffffff;text-decoration: underline;font-family: Arial, Helvetica, sans-serif;}
a.hui:hover    {color: #ffffff;text-decoration: none;font-family: Arial, Helvetica, sans-serif;}
a.hui:active   {color: #0c54a0;text-decoration: underline;font-family: Arial, Helvetica, sans-serif;}
